* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1;
  font-weight: 400;
  background-color: #fff;
  color: #555;
  overflow-x: hidden;
}

.heading-primary,
.heading-secondary,
.heading-tertiary {
  font-weight: 700;
  letter-spacing: -0.5px;
}

.heading-primary {
  font-size: 5.2rem;
  line-height: 1.05;
  margin-bottom: 3.2rem;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 8rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.section {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
}
