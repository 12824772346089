@import "./base/base";

.content {
  padding: 8rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  border-radius: 999rem;
  width: 22%;
  margin-bottom: 2rem;
  box-shadow: 0 0 0 0.8rem #555;
  -webkit-box-shadow: 0 0 0 0.8rem #555;
}

.title {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 800;
  letter-spacing: -0.05rem;
  margin: 1rem 0;
  text-align: center;
}

.subtitle {
  font-size: 3rem;
  line-height: 1.4;
  margin: 0.4rem 0;
  text-align: center;
}

.skill-detail,
.detail {
  margin-top: 2rem;
  font-size: 2.2rem;
  line-height: 1.4;
}

.detail {
  text-align: center;
}

.detail a:link,
.detail a:visited {
  color: inherit;
}

.detail a:hover {
  color: #000;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.social-list {
  display: flex;
  list-style: none;
  justify-content: center;
  padding: 0;
  margin-bottom: 2.2rem;
}

.social-list li {
  padding: 1.2rem;
  color: white;
  font-size: 1.9rem;
  margin-top: 2rem;
}

.social-list li a:link,
.social-list li a:visited {
  color: #555;
}

.social-list li a:hover {
  color: #000;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.button {
  font-family: inherit;
  background-color: #fff;
  border: 1px solid #555;
  border-radius: 3px;
  color: #555;
  padding: 1rem 3.2rem;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
}

.button:hover {
  background-color: #000;
  color: #fff;
}

.contact-icon {
  margin-right: 1rem;
}

/****************************/
/* HEADER */
/****************************/

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 9.6rem;
  padding: 0 4.8rem;
  position: relative;
}

.logo {
  height: 5rem;
}

/****************************/
/* NAVIGATION */
/****************************/

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
}

.main-nav-list > * {
  margin-left: 3.2rem;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
  text-align: center;
}

.main-nav-active:link,
.main-nav-active:visited {
  color: #9b9b9b;
}

.main-nav-link.nav-cta:hover,
.main-nav-link.nav-cta:active {
  background-color: #000;
}

/****************************/
/* PORTFOLIO */
/****************************/

.section-portfolio {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6.4rem;
  margin-top: 9.6rem;
}

.portfolio-item {
  text-align: center;
  overflow: hidden;
}

.portfolio-img {
  overflow: hidden;
  margin-bottom: 2rem;
}

.portfolio-img img {
  width: 100%;
  transition: all 0.4s;
}

.portfolio-img img:hover {
  transform: scale(1.1);
}

.portfolio-details {
  padding: 1.2rem 3.2rem;
}

.portfolio-item-heading {
  font-size: 2.6rem;
  font-weight: 600;
  margin-bottom: 1.2rem;
}

.portfolio-item-description {
  font-size: 2rem;
  line-height: 2.8rem;
}

/****************************/
/* COURSES */
/****************************/

.section-courses {
  display: grid;
  grid-template-columns: 25fr 75fr;
  row-gap: 3.8rem;
  column-gap: 3.2rem;
  margin-top: 9.6rem;
}

.course-image {
  width: 100%;
  overflow: hidden;
}

.course-image img {
  width: 100%;
}

.course-heading {
  font-size: 2.4rem;
  font-weight: 500;
}

.course-description {
  font-size: 2.2rem;
  line-height: 2.7rem;
  margin-bottom: 1.8rem;
}

/****************************/
/* SKILLS */
/****************************/

.section-skills {
  margin-top: 9.2rem;
}

.skills-description {
  font-size: 2.2rem;
  line-height: 2.2;
  margin-bottom: 3.2rem;
}

/****************************/
/* EXPERIENCE */
/****************************/

.experience-heading {
  font-size: 3.2rem;
  font-weight: 700;
  margin-bottom: 4.2rem;
  line-height: 1.2;
}

.experience-details {
  font-size: 2.2rem;
  line-height: 1.8;
  margin-bottom: 5.2rem;
}

/****************************/
/* MOBILE */
/****************************/

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}
