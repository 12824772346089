@media (max-width: 64em) {
  html {
    font-size: 56.25%;
  }
}

@media (max-width: 48em) {
  html {
    font-size: 50%;
  }
  .profile-pic {
    width: 30%;
  }
  .section-portfolio {
    grid-template-columns: repeat(2, 1fr);
  }
  .experience-details {
    line-height: 1.9;
  }
}

@media (max-width: 37em) {
  .title {
    font-size: 5.6rem;
  }
  .profile-pic {
    width: 36%;
  }
  .experience-heading {
    font-size: 4rem;
  }
}

@media (max-width: 30em) {
  .profile-pic {
    width: 40%;
  }
  .main-nav {
    background-color: rgba(255, 255, 255, 0.97);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    text-align: center;
    transition: all 0.5s ease-out;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: 9999;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
    z-index: 9999;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-list > * + * {
    margin-top: 4.8rem;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }
  .btn-mobile-nav {
    display: block;
    z-index: 9999;
  }

  .section-portfolio {
    grid-template-columns: 1fr;
  }

  .section-courses {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 23em) {
  .profile-pic {
    width: 50%;
  }
}
